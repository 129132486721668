






























import { defineComponent } from "@vue/composition-api";
import validator from "validator";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        phone: "",
        email: "",
        website: "",
      }),
    },
  },

  setup(_, { root }) {
    const rules = {
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          "Podaj poprawny numer telefonu",
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      isUrl: [
        (v: string) =>
          !v || validator.isURL(v) || root.$tc("layout.misc.validUrl"),
      ],
    };

    return { rules };
  },
});
